/* eslint-disable  no-restricted-globals */
import Application from '@ember/application';

import { importSync, isTesting, macroCondition } from '@embroider/macros';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import windowMock from 'ember-window-mock';

import { loadSmoothScrollPolyfill } from 'qonto/utils/polyfill';

import config from './config/environment';
import { registerJsURL } from './constants/hosts';
import * as Sentry from './sentry';
import { setupDayjsAndOverrideLocales } from './setup-dayjs';

if (macroCondition(!isTesting())) {
  importSync('./corejs-bundle');
  Sentry.init();
}

try {
  if (windowMock.location.pathname.includes('register')) {
    let path = windowMock.location.pathname.replace(/\/register/, '');
    let search = windowMock.location.search;
    windowMock.location.replace(registerJsURL + path + search);
  }
  //eslint-disable-next-line no-empty
} catch {}

// clickjacking prevention (BUG-1876)
// see http://web.archive.org/web/20170430064506/https://www.codemagi.com/blog/post/194

// we need to use the original window object to refer to the correct window reference
// otherwise this will break cypress e2e tests
if (window.self !== window.top) {
  window.top.location = window.self.location;
}

setupDayjsAndOverrideLocales();
loadSmoothScrollPolyfill();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  /** Basic logging, e.g. "Transitioned into 'post'"
   * Disable this line to activate
   */
  // LOG_TRANSITIONS = true;

  /** Extremely detailed logging, highlighting every internal
   * step made while transitioning into a route, including
   * `beforeModel`, `model`, and `afterModel` hooks, and
   * information about redirects and aborted transitions
   * Disable this line to activate
   */
  // LOG_TRANSITIONS_INTERNAL = true;
}

loadInitializers(App, config.modulePrefix);
