import { service } from '@ember/service';

import { getSCAEnforcementError } from '@qonto/qonto-sca/utils/mfa-error';
import { keepLatestTask, rawTimeout, task } from 'ember-concurrency';
import BaseAuthenticator from 'ember-simple-auth/authenticators/base';

import { authBaseURL, authUserNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export const SESSION_URL = `${authBaseURL}/${authUserNamespace}/users/sessions`;

const FIVE_MINUTES = 5 * 60 * 1000;

export default class CustomAuthenticator extends BaseAuthenticator {
  @service errors;
  @service toastFlashMessages;
  @service networkManager;
  @service notifierManager;
  @service sentry;
  @service userManager;
  @service scaManager;
  @service segment;

  restore({ expires, websocket_token }) {
    if (!expires) {
      return Promise.reject(new Error('No session data'));
    }

    let timeLeft = Date.parse(expires) - Date.now();
    if (timeLeft <= 0) {
      return Promise.reject(new Error('Session expired'));
    }

    // this prevents `setTimeout()` from being called with a negative timeout
    // if the session expires within the next 5 minutes.
    let timeUntilRefresh = Math.max(0, timeLeft - FIVE_MINUTES);
    this.scheduleRefresh(timeUntilRefresh);

    return Promise.resolve({ expires, websocket_token });
  }

  async invalidate() {
    this.scheduleRefreshTask.cancelAll();

    try {
      await this.networkManager.request(SESSION_URL, { method: 'DELETE' });
    } catch (error) {
      // we ignore all errors because throwing an error here will lead to
      // an infinite session invalidation loop

      // 401 is an expected failure after a session expiry timeout so we ignore
      // that without reporting it to Sentry, all other issues are reported to
      // Sentry.
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.withScope(scope => {
          scope.setLevel('warning');
          this.sentry.captureException(
            new Error(`Session invalidation failed with status ${error.status}`, { cause: error })
          );
        });
      }
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  }

  async authenticate(credentials) {
    let headers;
    // we're explicitly destructuring here because we don't want to save the
    // full response (including the `user` model) in the session data cookie
    // since the cookie size is limited
    let { device_remembered, expires, visit_token, websocket_token } = await this.networkManager
      .request(SESSION_URL, { method: 'POST', data: { user: credentials }, headers })
      .catch(error => {
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
        throw error;
      });

    let timeLeft = Date.parse(expires) - Date.now();
    this.scheduleRefresh(timeLeft - FIVE_MINUTES);

    this.segment.identify({ visit_token });

    return { device_remembered, expires, websocket_token };
  }

  scheduleRefresh(timeUntilRefresh) {
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.scheduleRefreshTask.perform(timeUntilRefresh);
  }

  scheduleRefreshTask = keepLatestTask(async timeUntilRefresh => {
    await rawTimeout(timeUntilRefresh);
    await this.refreshTask.perform();
  });

  /**
   * Use the existing authentication cookie to get a fresh one with a new
   * expiration date.
   */
  refreshTask = task(async () => {
    try {
      let { expires, visit_token, websocket_token } = await this.networkManager.request(
        SESSION_URL,
        {
          method: 'PUT',
        }
      );

      this.trigger('sessionDataUpdated', { expires, websocket_token });

      let timeLeft = Date.parse(expires) - Date.now();
      this.scheduleRefresh(timeLeft - FIVE_MINUTES);

      let userId = this.userManager.currentUser?.id;
      if (userId) {
        this.notifierManager.reconnect(websocket_token, userId);
      } else {
        // The user data was not loaded yet, and therefore the websocket connection was also not
        // established yet. Instead of calling `reconnect()` here, we rely on the `setup()` method
        // of the `userManager` service to call `connect()` instead, once the user data has
        // finished loading.
      }

      this.segment.identify({ visit_token });

      return { expires, websocket_token };
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && !getSCAEnforcementError(error)) {
        this.sentry.captureException(error);
      }
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });
}
